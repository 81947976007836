import React from "react";
import "./ContactMe.css";
import { FaEnvelope, FaFacebook, FaHandsHelping } from "react-icons/fa";
import contactImage from "/Users/tristanblais/jseguin_site/src/assets/images/IMG_1014.png"; // Adjust the path as needed

const ContactMe = () => {
  return (
    <section className="contact-container">
      {/* Left Side: Contact Info & Contribution Link */}
      <div className="contact-info">
        <h2>Contactez-moi</h2>
        <p className="contact-text">
          Vous souhaitez me contacter, poser des questions ou simplement discuter?
          Vous pouvez me joindre ici:
        </p>

        {/* Contact Buttons */}
        <div className="contact-buttons">
          <a href="mailto:your-email@example.com" className="contact-item email-button">
            <FaEnvelope className="contact-icon" /> Email
          </a>
          <a
            href="https://www.facebook.com/seguinjac"
            className="contact-item facebook-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="contact-icon" /> Facebook
          </a>
        </div>

        {/* Contribution Section */}
        <div className="contribution-section">
          <p className="contribution-text">
            Si tu veux <strong>aider la recherche</strong> sur la maladie de Parkinson, 
            je t'invite à jeter un coup d'oeuil à la fondation Michael J. Fox.
          </p>
          <a
            href="https://www.michaeljfox.org/"
            className="contact-item research-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaHandsHelping className="contact-icon" /> Aider la recherche
          </a>
        </div>
      </div>

      {/* Right Side: Image */}
      <div className="contact-image">
        <img src={contactImage} alt="Jacques Seguin" className="profile-photo" />
      </div>
    </section>
  );
};

export default ContactMe;
