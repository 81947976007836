import React from "react";
import "./BookSection.css";

const BookSection = () => {
  return (
    <div className="book-container">
      <div className="text-center">
        <h2 className="section-title">Mon Livre</h2>
        <p className="book-intro">
          J'ai toujours aimé écrire, alors, j'ai décidé de publier mon propre livre.  
          Il est principalement disponible en anglais, mais j'espère qu'il saura captiver tous ceux qui s'y plongent.
        </p>
      </div>

      <div className="book-card">
        <div className="book-left">
          <img
            src={require("../assets/images/61vCh9bquUL._AC_UF1000,1000_QL80_.jpg")}
            alt="Fictional Science Book Cover"
            className="book-image"
          />
          <div className="book-details">
            <h3 className="book-title">Fictional Science</h3>
            <p className="book-description">
              Have you ever gazed at the stars and wondered if there is life out there?  
              How and why Stonehenge was built? About the presence of aliens on Earth?  
              If mind transfers exist? How we could travel instantly from one place to another?  
              Then again, are you worried about terrorism or nuclear disarmament?
            </p>
            <p className="book-description">
              If these thoughts intrigue you, follow the Beings who caused the Big Bang,  
              came to Earth, and influenced Human evolution. Experience the journey of Beckires,  
              a young girl rescued during the destruction of Atlantis, who becomes the Protector  
              of Humans and the Keeper of the "In Here, Out There" portals.
            </p>
            <p className="book-description">
              As the world nears annihilation, Beckires uses her Seeker abilities to recruit  
              a group of warriors to restore balance. Facing the greatest challenge of all –  
              terrorism and nuclear disarmament – she and Bob Leboss create Fictional Science in Room #5.  
              But when she falls in love, she loses her immortality.  
              Will she still be able to save humanity from self-destruction?
            </p>
          </div>
        </div>

        <div className="buy-buttons">
          <a
            href="https://www.amazon.ca/Fictional-Science-Jacques-S%C3%A9guin-ebook/dp/B08RV9RG1B#customerReviews"
            target="_blank"
            rel="noopener noreferrer"
            className="buy-btn amazon"
          >
            Acheter sur Amazon
          </a>
          <a
            href="https://www.indigo.ca/en-ca/fictional-science/678ecd63-28c6-373c-8610-c281fa1c839e.html"
            target="_blank"
            rel="noopener noreferrer"
            className="buy-btn indigo"
          >
            Acheter sur Indigo
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookSection;
