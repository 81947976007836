import React from "react";
import "./Header.css";
import logo from "../assets/images/J_logo.png";
import mainImage from "/Users/tristanblais/jseguin_site/src/assets/images/IMG_4071.jpg";

const Header = () => {
  return (
    <header className="header">
      {/* Barre de navigation */}
      <nav className="navbar">
        <div className="nav-left">
          <a href="#"> {/* Wrap the logo in a link to scroll to top */}
            <img src={logo} alt="Logo" className="logo" />
          </a>
        </div>
        <div className="nav-center">
          <ul className="nav-links">
            <li><a href="#qui-suis-je">Mon Histoire</a></li>
            <li><a href="#serie-web">Vivre avec la maladie de Parkinson</a></li>
            <li><a href="#livres">Mon Livre</a></li>
            <li><a href="#medias">Médias</a></li>
            <li><a href="https://blogjseguin1.blogspot.com/?view=classic" target="_blank" rel="noopener noreferrer">Blog</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </nav>

      {/* Section Hero */}
      <div className="hero-section">
        <div className="hero-title">
          <h1 className="jacques">Jacques Seguin</h1>
        </div>
        <img src={mainImage} alt="Image principale" className="hero-image" />
      </div>
    </header>
  );
};

export default Header;
