// src/Views/HomePage.js
import React from 'react';
import './HomePage.css';
import Header from './Header.js';
import Introduction from './Introduction.js';
import WebSeries from './WebSeries.js';
import BookSection from './BookSection.js';
import MediaSection from './MediaSection.js';
import ContactMe from './ContactMe.js';

const HomePage = () => {
    return (
        <div className="cafe-homepage">
            <Header />
            <section id="qui-suis-je"><Introduction /></section>
            <section id="serie-web"><WebSeries /></section>
            <section id="livres"><BookSection /></section>
            <section id="medias"><MediaSection /></section>
            <section id="contact"><ContactMe /></section>
        </div>
    );
};

export default HomePage;
