import React from "react";
import { motion } from "framer-motion";
import "./Introduction.css";
import introImage from "/Users/tristanblais/jseguin_site/src/assets/images/IMG_3097.png";
import finalImage from "/Users/tristanblais/jseguin_site/src/assets/images/Screenshot 2025-02-28 at 2.51.29 AM.png";

const timelineData = [
  {
    year: "2003",
    title: "Diagnostic de Parkinson",
    description: "Je remarque les premiers symptômes : tremblements, difficulté à monter les escaliers, bras droit immobile en marchant.",
  },
  {
    year: "Octobre 2003",
    title: "Confirmation du diagnostic",
    description: "La neurologue me confirme que j’ai le Parkinson. Je dois apprendre à vivre avec cette réalité.",
  },
  {
    year: "Novembre - Décembre 2003",
    title: "Adaptation",
    description: "J'affronte un monstre qui me lance de nouveaux défis. J'adapte mon quotidien avec des outils comme un dictaphone et un rasoir électrique.",
  },
  {
    year: "2004",
    title: "Évolution de la maladie",
    description: "Fatigue extrême, premiers médicaments, perte du droit de conduire. Je quitte mon travail en juin.",
  },
  {
    year: "2005",
    title: "Découverte du Racquetball",
    description: "Je réalise que jouer au racquetball réduit mes symptômes. Petit à petit, je reprends ma forme.",
  },
  {
    year: "2010",
    title: "Ambassadeur du Parkinson",
    description: "Racquetball Canada me nomme ambassadeur pour le Parkinson et les maladies neurologiques.",
  },
];

const Introduction = () => {
  return (
    <section id="qui-suis-je" className="introduction-container">
      {/* Introduction Section with Image */}
      <div className="intro-wrapper">
        <div className="intro-text">
          <h1>Mon Histoire</h1> {/* Restored Title */}
          <p>
            Bonjour, mon nom est <strong>Jacques Séguin</strong>. J’habite à Ottawa et je suis Franco-ontarien.
            J’ai été diagnostiqué avec le Parkinson à l’âge de 51 ans en 2003.
          </p>
          <p>
            Je me suis rendu compte qu’il y avait quelque chose d’anormal avec mon corps lorsque je commençais un nouvel emploi.
            Un simple spasme musculaire dans ma jambe droite a été le premier signe d’un long combat.
          </p>
        </div>
        <div className="intro-image">
          <img src={introImage} alt="Jacques Séguin" />
        </div>
      </div>

      {/* Timeline */}
      <h2 className="timeline-title">Mon Parcours avec le Parkinson</h2>
      <div className="timeline-wrapper">
        <div className="timeline">
          {timelineData.map((item, index) => (
            <motion.div
              key={index}
              className="timeline-item"
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <div className="timeline-year">{item.year}</div>
              <div className="timeline-content">
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Conclusion Section with Image */}
      <div className="conclusion-wrapper">
        <div className="conclusion-text">
          <h2>Un message d’espoir</h2>
          <p>
            Le Parkinson m’a placé dans un univers parallèle où je me lève chaque matin
            en décidant si je prends le contrôle ou si je laisse la maladie gagner.
          </p>
          <p>
            J’ai la chance d’avoir une famille aimante – <strong>4 filles et 7 petits-enfants</strong>.
            Pour eux, je veux laisser le souvenir d’un grand-papa malade, mais debout.
          </p>
        </div>
        <div className="conclusion-image">
          <img src={finalImage} alt="Final Image" />
        </div>
      </div>
    </section>
  );
};

export default Introduction;
