import React, { useState } from 'react';
import './WebSeries.css'; // Adjust path if necessary

const episodes = {
  fr: [
    { title: "Épisode 1 : Introduction", url: "https://www.youtube.com/embed/iroKp4WvvD4" },
    { title: "Épisode 2 : Jacques : Choisir comment je vais vivre", url: "https://www.youtube.com/embed/Ee3cnKbgxCg" },
    { title: "Épisode 3 : Karl : Un nouveau sommet", url: "https://www.youtube.com/embed/1VRX2Relv-k" },
    { title: "Épisode 4 : Johanne : Adapté à mon style de vie", url: "https://www.youtube.com/embed/1F8HG2G--mE" },
    { title: "Épisode 5 : Se sentir bien EST la motivation", url: "https://www.youtube.com/embed/40CUMm5c0jk" },
    { title: "Épisode 6 : Peter : Cela vient tout naturellement", url: "https://www.youtube.com/embed/Qw3ldvzGcJM" }
  ],
  en: [
    { title: "Episode 1: Introduction", url: "https://www.youtube.com/embed/hri_Lsvpqz4" },
    { title: "Episode 2: Jacques: How I choose to live with it", url: "https://www.youtube.com/embed/AooqFlrDt2g" },
    { title: "Episode 3: Karl: Reaching a new high", url: "https://www.youtube.com/embed/K9aPg_QVMo8" },
    { title: "Episode 4: Johanne: Adapted to my lifestyle", url: "https://www.youtube.com/embed/S75mjx2SjFQ" },
    { title: "Episode 5: Feeling good IS the motivation", url: "https://www.youtube.com/embed/MrGw4lKLl3w" },
    { title: "Episode 6: Peter: It just comes naturally", url: "https://www.youtube.com/embed/Qw3ldvzGcJM" }
  ]
};

const WebSeries = () => {
  const [language, setLanguage] = useState("fr"); // Default to French
  const [selectedEpisode, setSelectedEpisode] = useState(episodes["fr"][0]); // Default to first French episode

  return (
    <div className="web-series">
      <h2 className="title">Une Belle Réalité - Vivre avec la maladie de Parkinson</h2>
      <p className="subtitle">Présenté par <span className="highlight">Racquetball Canada</span></p>
      
      <div className="language-toggle">
        <button 
          className={language === "fr" ? "active" : ""}
          onClick={() => { setLanguage("fr"); setSelectedEpisode(episodes["fr"][0]); }}
        >
          Français
        </button>
        <button 
          className={language === "en" ? "active" : ""}
          onClick={() => { setLanguage("en"); setSelectedEpisode(episodes["en"][0]); }}
        >
          English
        </button>
      </div>

      <div className="video-container">
        <iframe 
          src={selectedEpisode.url} 
          title={selectedEpisode.title}
          allowFullScreen
        ></iframe>
        <div className="episode-title">{selectedEpisode.title}</div>
      </div>

      <div className="episode-grid">
        {episodes[language].map((episode, index) => (
          <div 
            key={index} 
            className={`episode ${selectedEpisode.title === episode.title ? "selected" : ""}`} 
            onClick={() => setSelectedEpisode(episode)}
          >
            {episode.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebSeries;
