import React from 'react';
import './MediaSection.css';

// Importing images
import documentaryImg from '/Users/tristanblais/jseguin_site/src/assets/images/Screenshot 2025-02-28 at 3.59.42 PM.png';
import articleImg from '/Users/tristanblais/jseguin_site/src/assets/images/IMG_0316.png';

// YouTube Video Data
const youtubeVideos = [
  { title: "Le sport pour survivre", url: "https://www.youtube.com/embed/IJrbEnNGO3o", date: "📅 2013" },
  { title: "Global News Montréal", url: "https://www.youtube.com/embed/w9t0UBAJnnQ", date: "📅 2013" },
  { title: "CTV News", url: "https://www.youtube.com/embed/CL5HEhkmvb4", date: "📅 2014" },
  { title: "Reportage avec TVA", url: "https://www.youtube.com/embed/lG0206uTXk8", date: "📅 2013" },
  { title: "Reportage Télévision", url: "https://www.youtube.com/embed/hsNEAFNtR20", date: "📅 2013" },
  { title: "C'est la vie", url: "https://www.youtube.com/embed/XM_CL5bVJBM", date: "📅 2012" },
];

const MediaSection = () => {
  return (
    <section className="media-section">
      <h1 className="media-title">🎬 Médias</h1>

      {/* Documentary & Article Section */}
      <div className="media-grid">
        {/* Documentary */}
        <div className="media-card">
          <img src={documentaryImg} alt="Vivre vieux - Documentaire" className="media-image" />
          <div className="media-content">
            <h2>📽️ Vivre vieux</h2>
            <p>Un documentaire de Radio-Canada où Jacques Séguin partage son quotidien et sa résilience face au Parkinson.</p>
            <ul>
              <li><strong>📅 Date :</strong> 20 mars 2021</li>
              <li><strong>⏳ Durée :</strong> 49 min 10 sec</li>
              <li><strong>🎬 Réalisation :</strong> Léa Pascal</li>
            </ul>
            <a href="https://ici.radio-canada.ca/tele/doc-humanite/site/episodes/519919/vivre-vieux" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="media-link">
              🔗 Regarder sur Radio-Canada
            </a>
          </div>
        </div>

        {/* Article */}
        <div className="media-card">
          <img src={articleImg} alt="Coups de poing contre le Parkinson - Article" className="media-image" />
          <div className="media-content">
            <h2>📰 Coups de poing contre le Parkinson</h2>
            <p>Un article sur l'impact de la boxe contre le Parkinson, avec l’expérience de Jacques Séguin.</p>
            <a href="https://ici.radio-canada.ca/nouvelle/1029483/coups-de-poing-contre-parkinson-lutte-boxe" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="media-link">
              🔗 Lire l’article complet
            </a>
          </div>
        </div>
      </div>

      {/* TV Appearances Section */}
      <h2 className="media-subtitle">📺 Apparitions à la télévision</h2>
      <div className="video-grid">
        {youtubeVideos.map((video, index) => (
          <div key={index} className="video-card">
            <iframe 
              src={video.url} 
              title={video.title} 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
            <h3>{video.title}</h3>
            <p className="video-date">{video.date}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MediaSection;
